import React from 'react';

import spinner from '../../../public/spinner.gif'


const Spinner = () => {
    return ( 

        <img src={spinner} alt='Cargando...'/>


     );
}
 
export default Spinner;